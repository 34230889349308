
.dark-a{
    color: black !important;
}

.header-col{
    padding-top: 15px;
}
.modal-header{
    padding-top: 3px;
    padding-bottom: 0px;
}
.modal-body{
    padding-top: 0px;
}

.loading-wrapper{
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 500px;
    min-width: 200px;
}
.spinner-border{
    width: 70px;
    height: 70px;
    border-width: 5px;
}
.aligner-item--top {
    align-self: flex-start;
  }
.contact-href{
    color: #1d1d24;
}
.contact-href:hover{
    color: #093acb;
}

.modal-fullscreen-custom .modal-dialog{
    width: 100vw;
    max-width: none;
    height: 100% !important;
    margin: 0;
    overflow-y: hidden !important;
}
.modal-fullscreen-custom::-webkit-scrollbar {
    display: none;
  }
.modal-fullscreen .modal-header {
    border-radius: 0;
}
.modal-fullscreen .modal-body {
    overflow-y: auto;
}
.modal-fullscreen-custom .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
}

.legal-body{
    padding: 40px;
    overflow-y: auto;
}
.small-text{
    font-size: small !important;
}
.big-space-bottom{
    margin-bottom: 45px !important ;
}
.space-bottom{
    margin-bottom: 30px  ;
}
.small-space-bottom{
    margin-bottom: 15px !important;
}
.form-padding-3{
    padding-right: 3vw;
    padding-left: 3vw;
}
.form-padding-2{
    padding-right: 2vw;
    padding-left: 2vw;
}

.form-padding-5{
    padding-right: 5vw;
    padding-left: 5vw;
}

.slider-section{
    padding: 30px;
    padding-bottom: 50px;
    border-radius: 15px;
}

.payment-section{
    width: 250px;
    border-radius: 15px;
    margin-bottom: 5px;
    padding-top: 5px;
}
.get-offer-button{
    color: black;
    font-size: small;
    margin-bottom: 5px;
    padding-top: 5px;
    border-radius: 15px;
}

.blockquote-footer{
    margin-bottom: 2px;
}
.next-button{
    color: black;
    max-height: 78px;
}
.next-button:hover{
    
    background-color: #0184f0;
    border-color: #0184f0;
}
.next-button:disabled{
    color: black;
}
.contact-section{
    padding-top: 20px;
    padding-bottom: 40px;
    padding-right: 5vw;
    padding-left: 5vw;
    border-radius: 15px;
}
.summary-section{
    padding-bottom: 5px;
    padding-right: 5vw;
    padding-left: 5vw;
    border-radius: 15px;
}
.custom-link{
    color: #0184f0 !important;
}
.rc-slider-handle {
    border: solid 2px #0184f0;
}
.rc-slider-handle:hover {
    border: solid 2px #093acb;
}
.completed-modal-body{
    min-height: 60vh;
}
.small-paragraph{
    font-size: small;
}
.small-input{
    max-width: 150px;
}
.custom-checkbox{
    width: 1em !important;
    height: 1em !important;
    padding: 0 !important;
}

.small-space-bottom{
    margin-bottom: 10px;
}

.fake-link-span {
    cursor:pointer;
    color:#0184f0;
}
.fake-link-span:hover {
        text-decoration:none;
        text-shadow: 1px 1px 1px #555;
}
.small-text-description{
    font-size: 0.7rem;
    margin-top: -15px;
}
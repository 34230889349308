.scroll-top {
    cursor:pointer;
    position: fixed;
    bottom:40px;
    right:30px;
    background-color: white;
    border-radius: 50%;
    width: 60px !important;
    height: 60px !important;
    padding-top: 5px;
}



.padded-icon{
    padding: 8px;
}
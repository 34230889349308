.fake-link {
    cursor:pointer;
    color:rgb(10, 10, 63);
    text-decoration:underline;
    font-size: small;
}

.fake-link:hover {
    text-decoration:none;
    text-shadow: 1px 1px 1px #555;
    font-size: small;
}

.padded-icon{
    padding: 8px;
}
.long-paragraph{
    font-size:20px !important;
}
.loading-page-wrapper{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh ;
    background-color: whitesmoke;
}

.content-div{
    padding-bottom: 20vh;
}
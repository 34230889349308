.property-image{
    min-height: 30vh;
    background: url(https://upload.wikimedia.org/wikipedia/commons/b/b1/Loading_icon.gif) no-repeat center;
}
.sold-text{
    color: white;
    position:absolute; right:0px; top:0px;
    background-color: rgba(1, 132, 240, 0.7);
    border-radius: 0px 0px 0px 30px ;
    padding: 10px;
    z-index: 5;
}